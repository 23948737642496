
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, pathImg, request, truncatedHtml } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';
import BoxSideBarNav from '../components/Common/BoxSideBarNav';
import Pagination from "react-js-pagination";



export default function ChuyenMucCap3Layout({ chuyenmuc, showsidebar = true }) {
    const params = useParams();
    console.log(params);

    const [itemsPerPage, setItemsPerPage] = useState(8);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [items, setItems] = useState();

    useEffect(() => {
        (async function () {
            const vbs = await request(`Public/PostsByCategory?ID=${params.id}&skip=0&take=${itemsPerPage}&requireTotalCount=true`);
            setTotalCount(vbs.totalCount);
            setItems(vbs.data);
        })();
    }, [])


    // Invoke when user click to request another page.
    const handlePageClick = async (pageNumber) => {
        const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
        const vbs = await request(`Public/PostsByCategory?ID=${params.id}&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
        setActivePage(pageNumber);
        setTotalCount(vbs.totalCount);
        setItems(vbs.data);
    };
    const handleChangePage = (event) => {
        setItemsPerPage(event.target.value);
    };

    return (
        <div className="htvb-box">
            {showsidebar &&
                <div className="box-sidebar">
                    <BoxSideBarNav idChuyenMuc={chuyenmuc.data.ID} showSame={true} />
                </div>
            }
            <div className="box-content">
                <div className="list-tt-c3">
                    {items && items.map((item) =>

                        <div className="item">
                            <div className="date-box">
                                <Moment format="DD/MM/YYYY">
                                    {item.NgayXuatBan}
                                </Moment>
                            </div>

                            <div className="text-content">
                                <h2 className="post-title">
                                    <a href={`/baiviet/${item.ID}`}>{item.TieuDe}</a>
                                </h2>
                                <p className="post-desc" dangerouslySetInnerHTML={{ __html: truncatedHtml(item.MoTa) }}>
                                </p>
                            </div>
                            <a href={`/baiviet/${item.ID}`} className="post-image">
                                <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                            </a>
                        </div>
                    )}
                </div>
                <div className="pagination-bottom">
                    <div className="page-text">
                        <span className="text-m">Số tin mỗi trang</span>
                        <div className="select-custom">
                            <select title="Số tin mỗi trang" name="" id="" className="form-control" onChange={handleChangePage}>
                                <option value="">8</option>
                                <option value="">10</option>
                                <option value="">20</option>
                            </select>
                        </div>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemClass='page-item'
                        linkClass='page-link'
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageClick}
                    />
                </div>
            </div>
        </div>
    );
}
