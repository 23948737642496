
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import TreeView from 'devextreme-react/tree-view';
import config from '../config';
import { createCustomStore, groupBy, pathImg, pathImgBlank, request, sliceIntoChunks } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TreeCoCauToChuc from '../components/Common/TreeCoCauToChuc';
import { Helmet } from 'react-helmet';
import BoxSideBarCoCauToChuc from '../components/Common/BoxSideBarCoCauToChuc';

export default function CoCauToChucChiTiet() {
	const { t } = useTranslation();
	const [tochuc, setTochuc] = useState();
	const [lanhdao, setLanhdao] = useState();
	const params = useParams();
	const id = params.id;
	const idlanhdao = params.idlanhdao;
	useEffect(() => {
		(async function () {
			if (id) {
				const bans = await request(`/Public/CoCauToChucDetail?ID=${id}`);
				setTochuc(bans);
			}
			if (idlanhdao) {
				const bans = await request(`/Public/ThongTinLanhDaoDetail?ID=${idlanhdao}`);
				setLanhdao(bans);
			}
		})();
	}, [])
	const LanhDaoBox = ({ item }) => {
		return (
			<div className="box-user -top">
				<div className="avatar img-bg" style={{ backgroundImage: `url("${pathImgBlank(item.AnhDaiDien)}")` }}>
				</div>
				<div className="user-info article">
					<p>{item.ChucVi}</p>
					<div><strong>{item.HoTenLanhDao}</strong></div>
					<p>Điện thoại:</p>
					<p>{item.SoDienThoai}</p>
					<a href={`/cocautochuc/${id}/${item.ID}`}>Chi tiết</a>
				</div>
			</div>
		)
	}
	const LanhDaoBoxChild = ({ item }) => {
		return (
			<div className="box-user">
				<div className="avatar img-bg" style={{ backgroundImage: `url("${pathImgBlank(item.AnhDaiDien)}")` }}>
				</div>
				<div className="user-info">
					<p>{item.ChucVi}</p>
					<div><strong>{item.HoTenLanhDao}</strong></div>
					<a href={`/cocautochuc/${id}/${item.ID}`}>Chi tiết</a>
				</div>
			</div>
		)
	}

	const LanhDaoRow = ({ row }) => {
		let rowLen = row.length;
		let classTmp = "col-sm-4";
		if (rowLen == 2) {
			classTmp = "col-sm-6";
		} else if (rowLen == 1) {
			classTmp = "col-sm-12";
		}
		return (
			<div className="row">
				{row && row.map((col) => (
					<div className={`${classTmp} col-xs-6`}>
						<LanhDaoBoxChild item={col} />
					</div>
				))
				}
			</div>
		)
	}

	const renderTree = (lanhdaos) => {

		if (!lanhdaos || lanhdaos.length == 0) {
			return;
		}
		let toplanhdao = lanhdaos.filter(x => x.Hang == 1).shift();
		// let rows = sliceIntoChunks(lanhdaos, 3);
		let rows = groupBy(lanhdaos, "Hang");
		return (
			<>
				{toplanhdao && <LanhDaoBox item={toplanhdao} />}
				{rows && rows['2'] &&
					<LanhDaoRow row={rows['2']} />
				}
				{rows && rows['3'] &&
					<LanhDaoRow row={rows['3']} />
				}
				{rows && rows['4'] &&
					<LanhDaoRow row={rows['4']} />
				}
			</>
		);
	}
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI - Cơ cấu tổ chức`}</title>
			</Helmet>
			<div className="row">
				<div className="main-left col-md-8 col-sm-8 col-12">
					<div className="breadcrumb">
						<a href="/" className="breadcrumb-item">Trang chủ</a>
						<a href="/cocautochuc" class="breadcrumb-item">Cơ cấu tổ chức</a>
						{tochuc && tochuc.parents && tochuc.parents.map((item) =>
							<a href={`/cocautochuc/${item.ID}`} className="breadcrumb-item">{item.TenDonVi}</a>
						)}
						{tochuc &&
							<a href={`/cocautochuc/${tochuc.data.ID}`} class="breadcrumb-item">{tochuc.data.TenDonVi}</a>
						}
						{lanhdao &&
							<a href={`/cocautochuc/${tochuc.data.ID}/${lanhdao.data.ID}`} class="breadcrumb-item">{lanhdao.data.HoTenLanhDao}</a>
						}
					</div>
					{tochuc && !lanhdao &&
						<div className="co-ca-to-chuc">
							<div className="top-desc">
								<p><strong>{tochuc.data.TenDonVi}</strong></p>
								<div className='row mx-0'>
									{tochuc.data.DiaChi && <><div className='col px-0'><span class="icon isax-location1"></span>&nbsp;{tochuc.data.DiaChi}</div></>}
									{tochuc.data.Phone && <><div className='col px-0'><span class="icon isax-call1"></span>&nbsp;{tochuc.data.Phone}</div></>}
								</div>

								{tochuc.data.Email && <><div><span class="icon isax-sms1"></span>&nbsp;{tochuc.data.Email}</div></>}
								{tochuc.data.Website && <><div><span class="icon isax-chrome1"></span>&nbsp;{tochuc.data.Website}</div></>}

							</div>
							<div className="nav nav-tabs" style={{ marginTop: '20px' }}>
								<a href="#lddv" className="nav-link active" data-toggle="tab">Lãnh đạo đơn vị</a>
								{tochuc.childs.length > 0 &&
									<a href="#tctt" className="nav-link" data-toggle="tab">Tổ chức trực thuộc</a>
								}
								{tochuc.data.IDLoaiDV != 3 &&
									<a href="#cnnv" className="nav-link" data-toggle="tab">Chức năng nhiệm vụ</a>
								}
							</div>
							<div className="tab-content" style={{ border: '1px solid #ddd', padding: '20px' }}>
								<div className="tab-pane active" id="lddv" style={{}}>
									<div className="lanh-dao-bo">
										<h2 className="title upper bold center f18 mb-4">{tochuc.data.TenDonVi}</h2>
										{renderTree(tochuc.lanhdaos)}
									</div>
								</div>
								<div className="tab-pane" id="tctt">
									<ul className="list-link">
										{tochuc.childs.map((item, index) =>
											<li><a href={`/cocautochuc/${item.ID}`} className="link">{item.TenDonVi}</a></li>
										)
										}
									</ul>
								</div>
								<div className="tab-pane" id="cnnv" dangerouslySetInnerHTML={{ __html: tochuc.data.ChucNangNhiemVu }} >
								</div>
							</div>
						</div>
					}

					{lanhdao &&
						<>
							<div className="lanh-dao-bo">
								<h2 className="title upper bold center f18">
									{lanhdao.data && lanhdao.data.DonVi_LanhDao && lanhdao.data.DonVi_LanhDao.length > 0 &&
										lanhdao.data.DonVi_LanhDao[0].CoCauToChuc.TenDonVi
									}
								</h2>
								<div className="box-user -top">
									<div className="avatar img-bg" style={{ backgroundImage: `url("${window.env.REACT_APP_UPLOAD_URL}/${lanhdao.data.AnhDaiDien}")` }}>
									</div>
									<div className="user-info">
										<p>{lanhdao.data.ChucVi}</p>
										<div className="f18"><strong>{lanhdao.data.HoTenLanhDao}</strong></div>
										<p>Điện thoại: {lanhdao.data.SoDienThoai}</p>
										<br />
										{lanhdao.data.ThongTinThuKy &&
											<>
												<p>Thư ký: <a href={`/lanhdaobo/${lanhdao.data.ThongTinThuKy.ID}`}><strong>{lanhdao.data.ThongTinThuKy.HoTenLanhDao}</strong></a></p>
												<p>Điện thoại: {lanhdao.data.ThongTinThuKy.SoDienThoai}</p>
												<br />
											</>
										}
									</div>
								</div>
								<div className="article" dangerouslySetInnerHTML={{ __html: lanhdao.data.TieuSuBanThan }}>
								</div>
							</div>
						</>
					}
				</div>
				{/* Sidebar cơ cấu tổ chức */}
				<div className="col-sm-4 col-12">
					{tochuc && <BoxSideBarCoCauToChuc tochucs={tochuc.relates} />}
				</div>

			</div>
		</>
	);
}
