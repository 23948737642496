import React, { useState, useCallback, useRef, useEffect } from 'react';

import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxNew from '../components/Common/BoxNew';

export default function TinTuc() {
	const params = useParams();
	return (
		<>
			<div className="breadcrumb">
				<a href="/" className="breadcrumb-item">Trang chủ</a>
				<a href="/tintuc" className="breadcrumb-item">Tin Tức</a>
			</div>
			<h1 className="title-main main bold f18 mb-4 text-uppercase">Tin tức</h1>
			<div className="row">
				<BoxNew idChuyenmuc={11536} className="col-md-4 col-12" />
				<BoxNew idChuyenmuc={11537} className="col-md-4 col-12" />
				<BoxNew idChuyenmuc={26} className="col-md-4 col-12" />
				<BoxNew idChuyenmuc={286} className="col-md-4 col-12" />
				<BoxNew idChuyenmuc={254} className="col-md-4 col-12" />
			</div>

		</>
	);
}
