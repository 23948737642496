
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Footer, Header } from '../components';
import LayoutHome from '../layouts/LayoutHome';
import BoxRelate from '../components/Post/BoxRelate';
import BoxSideBarNav from '../components/Common/BoxSideBarNav';
import Breadcrumb from '../components/Common/BreadCrumb';
import Moment from 'react-moment';
import Notify from 'devextreme/ui/notify';
import BoxSideBarMenu from '../components/Common/BoxSideBarMenu';

export default function TinSidebarKhongAmThanhTinTucLayout({ post }) {
	const sendForm = useRef();
	useEffect(() => {
		(async function () {
			window.reloadZalo();
		})();
	}, [post])
	const changeFont = (val) => {
		let curVal = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size').replace("px"));
		document.body.style.fontSize = `${curVal + val}px`;
	}
	const handlePrint = () => {
		//console.log('print');  
		let printContents = document.getElementById('articleForPrint').innerHTML;
		let originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		window.location.reload();
	}
	const handleSend = async (event) => {
		event.preventDefault();

		let form = sendForm.current;
		const response = await fetch(`${window.env.REACT_APP_API_URL}/Public/SendMail`, {
			method: 'POST',
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: `fromUser=${form.fromUser.value}&toUser=${form.toUser.value}&fromEmail=${form.fromEmail.value}&toEmail=${form.toEmail.value}&title=${form.title.value}&address=${form.address.value}&content=${form.content.value}`
		});

		Notify({
			message: `Thành công gửi email`,
			type: 'success',
			displayTime: 3500,
		});
	}

	return (
		<>
			<Header
				menuToggleEnabled
			/>
			<div className={"main-wrapper"}>
				<div className="container">
					{/* <Breadcrumb>
                    <a href="/" className="breadcrumb-item">Trang chủ</a>
                    <a href="/tintuc" className="breadcrumb-item">Tin Tức</a>
                    {post.parents && post.parents.map((item, index) => {
                        if (index == 0) {
                            return;
                        }
                        return (
                            <a href={`/chuyenmuc/${item.ID}`} className="breadcrumb-item">{item.TenChuyenMuc}</a>
                        )
                    }
                    )}
                </Breadcrumb> */}
					<Breadcrumb>
						<a href="/" className="breadcrumb-item">Trang chủ</a>
						{/* <a href="/tintuc" className="breadcrumb-item">Tin Tức</a> */}
						{post.parents && post.parents.map((item, index) => {
							// if (index == 0) {
							// 	return;
							// }
							return (
								<a href={`/chuyenmuc/${item.ID}`} className="breadcrumb-item">{item.TenChuyenMuc}</a>
							)
						}
						)}
					</Breadcrumb>
					<div className="htvb-box">
						<div className="box-content">
							<>
								<div className="article">
									<h1 className="title-news f26">
										{post.data.TieuDe}
									</h1>
									<p>
										<strong dangerouslySetInnerHTML={{ __html: post.data.MoTa }}></strong>
									</p>
									<div dangerouslySetInnerHTML={{ __html: post.data.NoiDung }} />
									<div className='clearfix'>
										<a className='float-right' href={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`}>
											<img src={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`} alt="ncsc.png" />
										</a>
									</div>
								</div>
								<div id='articleForPrint' className='d-none'>
									<h1 className="title-news">
										{post.data.TieuDe}
									</h1>
									<div className="post-date f14">
										{post.data.NgayXuatBan && <Moment format="DD/MM/YYYY">
											{post.data.NgayXuatBan}
										</Moment>
										}
									</div>
									<span class="float-left"><strong>(Molisa.gov.vn)&nbsp;-&nbsp;</strong></span>
									<p>
										<strong dangerouslySetInnerHTML={{ __html: post.data.MoTa }}></strong>
									</p>
									<div dangerouslySetInnerHTML={{ __html: post.data.NoiDung }} />
									<div className='clearfix'>
										<a className='float-right' href={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`}>
											<img src={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`} alt="ncsc.png" />
										</a>
									</div>
								</div>
								<div className="divider-gray"></div>
								<BoxRelate id={post.data.ID} />
							</>
						</div>
						<div className="box-sidebar">
							<BoxSideBarMenu idMenu={1746} showChild={true} />
						</div>
					</div>
					<div className="modal" id="modalChitietin" aria-modal="true" role="dialog">
						<div className="modal-dialog" role="document" style={{ width: '100%', maxWidth: '1200px' }}>
							<div className="modal-content">
								<div className="modal-body px-4">
									<div className="list-actions-article">
										<div className="post-date italic">Molisa.gov.vn</div>
										<div className="group-buttons">
											<div className="fontsize-actions mb-0">
												<span className="text-m">Xem cỡ chữ:</span>
												<button className="zoom-in" onClick={() => changeFont(1)}>A</button>
												<button className="zoom-out" onClick={() => changeFont(-1)}>A</button>
											</div>
										</div>
									</div>
									<div className="article">
										<h1 className="title-news">
											{post.data.TieuDe}
										</h1>
										<div className="post-date f14">
											{post.data.NgayXuatBan && <Moment format="DD/MM/YYYY">
												{post.data.NgayXuatBan}
											</Moment>
											}
										</div>
										<span class="float-left"><strong>(Molisa.gov.vn)&nbsp;-&nbsp;</strong></span>
										<p><strong dangerouslySetInnerHTML={{ __html: post.data.MoTa }}></strong></p>
										<div dangerouslySetInnerHTML={{ __html: post.data.NoiDung }} />
										<div className='clearfix'>
											<a className='float-right' href={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`}>
												<img src={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`} alt="ncsc.png" />
											</a>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button className="btn fullw upper main fw6 " onClick={handlePrint}>
										<span className="isax-printer1 f32" /> IN TRANG
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal" id="modalGuiToiBan" aria-modal="true" role="dialog">
						<div className="modal-dialog" role="document" style={{ width: '100%', maxWidth: '1200px' }}>
							<div className="modal-content">
								<form ref={sendForm} onSubmit={handleSend}>
									<div className="modal-body px-4">
										<h1 className="title-main main f24" style={{ marginTop: '20px' }}>
											GỬI THÔNG TIN
										</h1>
										<div className="qa-canvas">
											<div className='row'>
												<div className='col'>
													<div className="form-group">
														<label className="label-text" htmlFor="fromUser">Người gửi: <span className="red">*</span></label>
														<input type="text" className="form-control" id="fromUser" />
													</div>
												</div>
												<div className='col'>
													<div className="form-group">
														<label className="label-text" htmlFor="fromEmail">Email người gửi: <span className="red">*</span></label>
														<input type="text" className="form-control" id="fromEmail" />
													</div>
												</div>

											</div>
											<div className='row'>
												<div className='col'>
													<div className="form-group">
														<label className="label-text" htmlFor="toUser">Người nhận: <span className="red">*</span></label>
														<input type="text" className="form-control" id="toUser" />
													</div>
												</div>
												<div className='col'>
													<div className="form-group">
														<label className="label-text" htmlFor="toEmail">Email người nhận: <span className="red">*</span></label>
														<input type="text" className="form-control" id="toEmail" />
													</div>
												</div>
											</div>
											<div className="form-group">
												<label className="label-text" htmlFor="title">Tiêu đề: <span className="red">*</span></label>
												<input type="text" className="form-control" id="title" />
											</div>
											<div className="form-group">
												<label className="label-text" htmlFor="address">Địa chỉ trang:</label>
												<input type="text" readOnly={true} className="form-control" id="address" value={`/baiviet/${post.data.ID}`} />
											</div>
											<div className="form-group">
												<label className="label-text" htmlFor="content">Nội dung: <span className="red">*</span></label>
												<textarea name id="content" cols={30} rows={3} className="form-control" />
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button type='submit' className="btn btn-submit btn-primary">
											GỬI
										</button>
										<button className="btn btn-submit btn-primary" data-dismiss="modal">
											Hủy
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
