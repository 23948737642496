
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, interleave, pathFile, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import Moment from 'react-moment';
import Pagination from "react-js-pagination";
import CheckBox from '../components/Common/CheckBox';
import { Helmet } from 'react-helmet';


const LinhVucTree = ({ item, LinhVucID }) => {
	const [expand, setExpand] = useState();
	return (
		<li className={LinhVucID == item.ID || expand ? "active" : ""} onClick={() => { setExpand(!expand) }}>
			{item.Childs.length > 0 && <div class="down"></div>}
			<a href={`/duan?LinhVucID=${item.ID}`}>{item.Ten}</a>
			{item.Childs.length > 0 &&
				<ul>
					{item.Childs && item.Childs.map((aChild) =>
						<li className={LinhVucID == aChild.ID ? "active" : ""}>
							<a href={`/duan?LinhVucID=${aChild.ID}`}>{aChild.Ten}</a>
						</li>
					)}
				</ul>
			}
		</li>
	)
}

export default function ChuongTrinhDuAnDeAn() {
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const [loading, setLoading] = useState(true);

	const [duan, setDuan] = useState();
	const [linhvucs, setLinhvucs] = useState();
	const [loaiduans, setLoaiduans] = useState();
	const [giaidoans, setGiaidoans] = useState();
	const [phamvis, setPhamvis] = useState();
	const [donvis, setDonvis] = useState();

	const [giaidoansChecks, setGiaidoansChecks] = useState([]);
	const [phamvisChecks, setPhamvisChecks] = useState([]);
	const [loaiduan, setLoaiduan] = useState(2); //mặc định là chương trình
	const [linhvuc, setLinhvuc] = useState(); //mặc định là chương trình

	const [itemsPerPage, setItemsPerPage] = useState(8);
	const [activePage, setActivePage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [items, setItems] = useState();
	const [filter, setFilter] = useState([]);

	const searchForm = useRef();
	const id = params.id;
	const LinhVucID = searchParams.get("LinhVucID");

	useEffect(() => {
		(async function () {

			const data = await request(`Public/QLDA_LinhVuc`);
			const data1 = await request(`Public/QLDA_LoaiDuAn`);
			const data2 = await request(`Public/QLDA_PhamVi`);
			const data3 = await request(`Public/QLDA_GiaiDoan`);
			const data4 = await request(`Public/QLDA_DonVi`);
			setLinhvucs(data);
			setLoaiduans(data1);
			setPhamvis(data2);
			setGiaidoans(data3);
			setDonvis(data4);

			if (id) {
				const duan = await request(`/Public/QLDA_DuAnDeAnDetail?ID=${id}`);
				setDuan(duan);
			}
			setLoading(false);
		})();
	}, [])

	useEffect(() => {
		if (!loading) {
			(async function () {
				reloadData(0);
			})();
		}
	}, [loaiduan, giaidoansChecks, phamvisChecks, loading]);

	async function reloadData(newOffset) {

		let aForm = searchForm.current;
		let filter = [];

		if (loaiduan) {
			filter.push(["LoaiID", "=", loaiduan]);
		}

		if (aForm.txtTuKhoa.value) {
			filter.push(["Ten", "contains", aForm.txtTuKhoa.value]);
		}
		if (aForm.txtSoKyHieu.value) {
			filter.push(["SoKyHieu", "contains", aForm.txtSoKyHieu.value]);
		}
		if (aForm.ddlDonViBanHanh.value) {
			filter.push(["DonViBanHanhID", "=", aForm.ddlDonViBanHanh.value]);
		}
		if (aForm.txtNgayBanHanhTu.value) {
			filter.push(["NgayBanHanh", "<=", aForm.txtNgayBanHanhTu.value]);
		}
		if (aForm.txtNgayBanHanhDen.value) {
			filter.push(["NgayBanHanh", "<=", aForm.txtNgayBanHanhDen.value]);
		}

		if (aForm.giaidoan) {
			let simpleFilter = [];
			let values = Object.values(aForm.giaidoan).filter(x => x.checked == true).map(x => x.value);
			values.forEach(function (aVal) {
				simpleFilter.push(["GiaiDoan", "=", aVal]);
			});
			if (simpleFilter.length > 0) {
				filter.push(interleave(simpleFilter, "or"));
			}
		}

		if (aForm.phamvi) {
			let simpleFilter = [];
			let values = Object.values(aForm.phamvi).filter(x => x.checked == true).map(x => x.value);
			values.forEach(function (aVal) {
				simpleFilter.push(["PhamVi", "=", aVal]);
			});

			if (simpleFilter.length > 0) {
				filter.push(interleave(simpleFilter, "or"));
			}
		}
		if (aForm.trangthaiduan.value) {
			let simpleFilter = [];
			let values = Object.values(aForm.trangthaiduan).filter(x => x.checked == true).map(x => x.value);
			values.forEach(function (aVal) {
				simpleFilter.push(["TrangThaiDuAn", "=", aVal]);
			});
			if (simpleFilter.length > 0) {
				filter.push(interleave(simpleFilter, "or"));
			}
		}

		if (aForm.txtThoiGianTu.value) {
			filter.push(["NgaybatDau", ">=", aForm.txtThoiGianTu.value]);
		}
		if (aForm.txtThoiGianDen.value) {
			filter.push(["NgayKetThuc", "<=", aForm.txtThoiGianDen.value]);
		}
		if (aForm.ddlLinhVuc.value) {
			filter.push(["LinhVucID", "=", aForm.ddlLinhVuc.value]);
		}
		setFilter(filter);

		let filterStr = JSON.stringify(interleave(filter, "and"));
		let sortStr = JSON.stringify([{ "selector": "id", "desc": true }]);
		const vbs = await request(`Public/QLDA_DuAnDeAn?skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true&filter=${filterStr}&sort=${sortStr}`);
		setTotalCount(vbs.totalCount);
		setItems(vbs.data);
	}

	const handleSearch = async (event) => {
		event.preventDefault();

		reloadData(0);
	}
	// Invoke when user click to request another page.
	const handlePageClick = async (pageNumber) => {
		const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
		setActivePage(pageNumber);
		reloadData(newOffset);
	};
	const handleGiaiDoanClick = async (GiaiDoanID) => {
		const news = giaidoansChecks.map((item) => item);
		if (!news.includes(GiaiDoanID)) {
			news.push(GiaiDoanID);
		} else {
			news.splice(news.indexOf(GiaiDoanID), 1);
		}
		setGiaidoansChecks(news);
	};

	const handlePhamViClick = async (PhamViID) => {
		const news = phamvisChecks.map((item) => item);
		if (!news.includes(PhamViID)) {
			news.push(PhamViID);
		} else {
			news.splice(news.indexOf(PhamViID), 1);
		}
		setPhamvisChecks(news);
	};
	return (
		<>
			{!duan &&
				<Helmet>
					<meta charSet="utf-8" />
					<title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Chương trình dự án đề án`}</title>
				</Helmet>
			}
			{duan &&
				<Helmet>
					<meta charSet="utf-8" />
					<title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-${duan.data.Ten}`}</title>
				</Helmet>
			}
			<div className="breadcrumb">
				<a href="/" className="breadcrumb-item">Trang chủ</a>
				<a href="/duan" class="breadcrumb-item">Chương trình dự án đề án</a>
				{duan &&
					<a href={`/duan/${duan.data.ID}`} class="breadcrumb-item">{duan.data.Ten}</a>
				}
			</div>
			<div className='row'>
				<div className="col-sm-3 col-12">
					<div className="box-sidebar">
						<div className="head">Lĩnh vực</div>
						<div className="body">
							<div className="box-tree">
								<ul className='tree'>
									{linhvucs && linhvucs.data.map((item) =>
										<LinhVucTree item={item} LinhVucID={LinhVucID} />
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="main-left col-md-9 col-sm-9 col-12">
					{!duan &&
						<div className="quan-ly-du-an">
							<form ref={searchForm} onSubmit={handleSearch}>
								<div className="box-dtvb">
									<div className="box-form">
										<div className="input-search">
											<input className="form-control" id="Keywords" name="Keywords" placeholder="Nhập nội dung văn bản cần tìm..." type="text" />
											<button className="btn btn-primary" type='submit'>
												<span className="isax-search-normal1">
												</span>
												Tìm kiếm
											</button>
										</div>
										<div className="top-actions" style={{ marginBottom: '0px' }}>
											<button type='button' className="btn btn-second collapsed" data-toggle="collapse" data-target="#formFilter" aria-expanded="false"><span className="-ap icon-repo" /> Tìm kiếm nâng cao <span className="-ap icon_fluent_caret_down_24_filled" /></button>
											<p>Tổng số <strong className="red">{totalCount}</strong></p>
										</div>
										<div className="filter-form mt-3 px-3 py-2 collapse" style={{ padding: '0px' }} id="formFilter" aria-expanded="false">
											<div className="note red">(điền nội dung vào một trong các ô tương ứng và nhấn nút Tìm kiếm)</div>
											<div className="form-group">
												<label htmlFor="txtTuKhoa" className="label-text">Từ khóa</label>
												<div className="input">
													<input id="txtTuKhoa" type="text" className="form-control" placeholder="Từ khóa, Tên, Trích yếu, Nội dung, ..." />
												</div>
											</div>
											<div className="form-group">
												<label className="label-text" htmlFor="fromdateNBH">Văn bản</label>
												<div className="input">
													<input type="text" className="form-control" id="txtSoKyHieu" placeholder="Số ký hiệu" />
												</div>
												<label htmlFor="ddlDonViBanHanh" className="label-text">Đơn vị ban hành</label>
												<div className="input">
													<div className="select-custom">
														<select id="ddlDonViBanHanh" className="form-control">
															<option selected="selected" value="">-Tất cả-</option>
															{donvis && donvis.data.map((item, index) =>
																<option value={item.ID}>{item.Ten}</option>
															)
															}
														</select>
													</div>
												</div>
											</div>
											<div className="form-group">
												<label htmlFor="txtNgayBanHanhTu" className="label-text">Ngày ban hành </label>
												<div className="input">
													<input type="date" className="form-control dateformat" placeholder="dd/mm/yyyy" id="txtNgayBanHanhTu" />
												</div>
												<label htmlFor="txtNgayBanHanhDen" className="label-text -date ">Đến</label>
												<div className="input">
													<input type="date" className="form-control dateformat" placeholder="dd/mm/yyyy" id="txtNgayBanHanhDen" />
												</div>
											</div>
											<div className="form-group">
												<label className="label-text" htmlFor="selCat">Lĩnh vực</label>
												<div className="input">
													<div className="select-custom">
														<select id="ddlLinhVuc" className="form-control">
															<option value="">-Tất cả-</option>
															{linhvucs && linhvucs.data.map((item, index) =>
																<option value={item.ID} selected={LinhVucID == item.ID}>{item.Ten}</option>
															)
															}
														</select>
													</div>
												</div>
											</div>
											<div className="form-group">
												<label className="label-text" htmlFor="content">Giai đoạn</label>
												<div className="input">
													{giaidoans && giaidoans.data.map((item, index) =>
														<label className="checkbox">
															<input type="checkbox" className="giaidoan" id='giaidoan' checked={giaidoansChecks.includes(item.ID)} onChange={() => handleGiaiDoanClick(item.ID)} defaultValue={item.ID} />{item.Ten}
														</label>
													)
													}
												</div>
												<label className="label-text" htmlFor="content">Phạm vi</label>
												<div className="input">
													{phamvis && phamvis.data.map((item, index) =>
														<label className="checkbox">
															<input type="checkbox" className="phamvi" id='phamvi' checked={phamvisChecks.includes(item.ID)} onChange={() => handlePhamViClick(item.ID)} defaultValue={item.ID} />{item.Ten}
														</label>
													)
													}
												</div>
											</div>
											<div className="form-group">
												<label className="label-text" htmlFor="content">Trạng thái</label>
												<div className="input">
													<label className="checkbox">
														<input type="checkbox" className="trangthaiduan" id='trangthaiduan' defaultValue={1} />Đã thực hiện
													</label>
													<label className="checkbox">
														<input type="checkbox" className="trangthaiduan" id='trangthaiduan' defaultValue={2} />Đang thực hiện
													</label>
													<label className="checkbox">
														<input type="checkbox" className="trangthaiduan" id='trangthaiduan' defaultValue={3} />Sẽ thực hiện
													</label>
												</div>
											</div>
											<div className="form-group">
												<label className="label-text" htmlFor="fromdateNBH">Thời gian thực hiện</label>
											</div>
											<div className="form-group">
												<label className="label-text" htmlFor="txtThoiGianTu">Từ</label>
												<div className="input">
													<input type="date" className="form-control dateformat" placeholder="dd/mm/yyyy" id="txtThoiGianTu" />
												</div>
												<label htmlFor="txtThoiGianDen" className="label-text -date">Đến</label>
												<div className="input">
													<input type="date" className="form-control dateformat" placeholder="dd/mm/yyyy" id="txtThoiGianDen" />
												</div>
											</div>
											<div className="actions form-group">
												<label className="label-text" />
												<div className="input">
													<button className="btn-submit btn-success btn timkiem" type='submit'>Tìm kiếm</button>
													<button className="btn-cancel btn btn-outline-secondary pD huytimkiem" data-toggle="collapse" data-target="#formFilter" aria-expanded="true">Hủy</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
							<div className="project-detail mt-3">
								<ul className="tabs nav">
									{loaiduans && loaiduans.data.map((item, index) =>
										<li className="nav-item">
											<a data-toggle="tab" href="#" className={`nav-link ${index == 0 ? "active" : ""}`} onClick={(e) => setLoaiduan(item.ID)}>{item.Ten}</a>
										</li>
									)}
								</ul>
								<div className="tab-content tab-content-parent">
									<div className="tab-pane active" id="chuongtrinh">
										<div className="row">
											<div className="col-sm-8 col-xs-12">
												{items && items.map((item, index) =>
													<div className="box-stage">
														<h2 className="title"><a href={`/duan/${item.id}`}>{item.Ten}</a></h2>
														<div className="stage">
															{item.Childs && item.Childs.map((aChild, index) =>
																<a className="item">
																	<span className="icon" />
																	<span className="name"><a href={`/duan/${aChild.id}`}>{aChild.Ten}</a></span>
																	<span className="date"></span>
																</a>
															)
															}
														</div>
													</div>
												)
												}
												<Pagination
													activePage={activePage}
													itemClass='page-item'
													linkClass='page-link'
													itemsCountPerPage={itemsPerPage}
													totalItemsCount={totalCount}
													pageRangeDisplayed={5}
													onChange={handlePageClick}
												/>
											</div>
											<div className="col-sm-4 col-xs-12">
												<div className="sidebar-filter">
													<div className="title">Giai đoạn</div>
													<ul className="list">
														{giaidoans && giaidoans.data.map((item) =>
															<CheckBox title={item.Ten} checked={giaidoansChecks.includes(item.ID)} onClick={() => handleGiaiDoanClick(item.ID)} />
														)}
													</ul>
												</div>
												<div className="sidebar-filter">
													<div className="title">Phạm vi thực hiện</div>
													<ul className="list">
														{phamvis && phamvis.data.map((item) =>
															<CheckBox title={item.Ten} checked={phamvisChecks.includes(item.ID)} onClick={() => handlePhamViClick(item.ID)} />
														)}
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
					{duan &&
						<div className="project-detail">
							<div className="head">
								<h1 className="title">{duan.data.Ten}</h1>
								{/* <p className="subtitle">Thời gian thực hiện đến </p> */}
							</div>
							<ul className="tabs-line nav">
								<li className="nav-item"><a data-toggle="tab" className="nav-link active" href="#thongtinchung" aria-expanded="true">Thông tin chung</a></li>
								<li className="nav-item"><a data-toggle="tab" className="nav-link" href="#noidungtomtat" aria-expanded="false">Nội dung tóm tắt</a></li>
								<li className="nav-item"><a data-toggle="tab" className="nav-link" href="#vanbanphapquy" aria-expanded="false">Văn bản pháp quy</a></li>
								<li className="nav-item"><a data-toggle="tab" className="nav-link" href="#hosolienquan">Hồ sơ liên quan</a></li>
							</ul>
							<div className="tab-content">
								<div className="tab-pane active" id="thongtinchung">
									<table className="tb-detail">
										<tbody>
											<tr>
												<td className="key">Tên chương trình:</td>
												<td className="value">{duan.data.Ten}</td>
											</tr>
											<tr>
												<td className="key">Giai đoạn thực hiện:</td>
												<td className="value">{giaidoans.data.find(gd => gd.ID === duan.data.GiaiDoanThucHienID).Ten}</td>
											</tr>
											<tr>
												<td className="key">Phạm vi thực hiện:</td>
												<td className="value">{phamvis.data.find(pv => pv.ID === duan.data.PhamViThucHienID).Ten}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="tab-pane" id="noidungtomtat" dangerouslySetInnerHTML={{ __html: duan.data.NoiDung }}>

								</div>
								<div className="tab-pane" id="vanbanphapquy">
									<table className="table">
										<thead id="headhoso">
											<tr>
												<th>Số ký hiệu</th>
												<th data-breakpoints="xs">Ngày ban hành </th>
												<th data-breakpoints="xs">Trích yếu</th>
											</tr>
										</thead>
										<tbody>
											{duan.data.QLDA_VanBan && duan.data.QLDA_VanBan.map((item) =>
												<tr>
													<td>{item.SoKyHieu}</td>
													<td>
														{item.NgayBanHanh && <>
															<Moment format="DD/MM/YYYY">
																{item.NgayBanHanh}
															</Moment>
														</>
														}
													</td>
													<td>
														{item.TrichYeu}
														{item.QLDA_VanBan_Files.map((aFile) =>
															<>
																<a href={pathFile(aFile.File.LinkLuuTru)}> <i class="icon isax-document-download1"></i></a>
															</>
														)}
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div className="tab-pane" id="hosolienquan">
									<table className="table">
										<thead id="headhoso">
											<tr>
												<th>Tên hồ sơ</th>
												<th data-breakpoints="xs">Ngày </th>
												<th data-breakpoints="xs">Trích yếu</th>
											</tr>
										</thead>
										<tbody>
											{duan.data.QLDA_HoSo && duan.data.QLDA_HoSo.map((item) =>
												<tr>
													<td>{item.Ten}</td>
													<td>
														{item.NgayTao && <>
															<Moment format="DD/MM/YYYY">
																{item.NgayTao}
															</Moment>
														</>
														}
													</td>
													<td>
														{item.NoiDung}
														{item.QLDA_HoSo_Files.map((aFile) =>
															<>
																<a href={pathFile(aFile.File.LinkLuuTru)}> <i class="icon isax-document-download1"></i></a>
															</>
														)}
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
							<div className="news-related">
								<h2 className="title-main"><span>Chương trình dự án khác</span></h2>
								<ul className="list-news">
									{duan && duan.relates && duan.relates.map((item) =>
										<li>
											<a href={`/duan/${item.id}`}>{item.Ten}</a>
										</li>
									)}
								</ul>
							</div>
							<div className="navi-page">

								<Pagination
									activePage={activePage}
									itemClass='page-item'
									linkClass='page-link'
									itemsCountPerPage={itemsPerPage}
									totalItemsCount={totalCount}
									pageRangeDisplayed={5}
									onChange={handlePageClick}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</>
	);
}
