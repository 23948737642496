import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import config from '../../config';
import { pathImg, proxyPathImg, request } from '../../utils/api';
import BoxSuKien from './BoxSuKien';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default function BoxNDDN({ limit = 5 }) {
	const [data, setData] = useState();
	const [banners, setBanners] = useState();
	useEffect(() => {
		(async function () {
			const data = await request(`Public/BoxNDDN?limit=${limit}`);
			setData(data);
			const bans = await request(`/Public/BannerByViTri?ID_ViTri=${window.env.REACT_APP_BOXNDDN_BANNER_TRANG_CHU_TIENG_VIET}`);
			setBanners(bans);
		})();
	}, [])

	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie'
		},
		title: {
			text: null,
			align: 'left'
		},
		tooltip: {
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		credits: {
			enabled: false
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				innerSize: '60%',
				size: '120%',
				dataLabels: {
					enabled: false,
					format: '<b>{point.name}</b>: {point.percentage:.1f} %'
				}
			}
		},
		series: [{
			name: 'Brands',
			colorByPoint: true,
			data: [{
				name: 'Câu hỏi chưa trả lời',
				y: data && data.faq ? (data.faq.chuatraloi / data.faq.total) * 100 : 0,
				color: '#F2994A'
				// color: '#28AE60'
			}, {
				name: 'Câu hỏi đã trả lời',
				y: data && data.faq ? (data.faq.datraloi / data.faq.total) * 100 : 0,
				color: '#28AE60'
				// color: '#F2994A'
			}, {
				name: 'Câu hỏi không hợp lệ',
				y: data && data.faq ? (data.faq.khonghople / data.faq.total) * 100 : 0,
				color: '#EC5454'
			}]
		}]
	}
	return (
		<>
			<div className="main-title">
				<div className="title">
					<span className="icon isax-buildings-2" />
					<h2 className="text-m"><a href="https://bovoinddn.molisa.gov.vn/">HỆ THỐNG THÔNG TIN BỘ VỚI NGƯỜI DÂN VÀ DOANH NGHIỆP</a></h2>
				</div>
			</div>
			<div className="htvb-box mb-4">
				<div className="box-content">
					<div className="row">
						<div className="col-md-6 col-12">
							{data && data.news.length > 0 &&
								<>
									<a className="post-image mb-2">
										<img src={proxyPathImg(data.news[0].LinkAnhDaiDien)} alt={pathImg(data.news[0].LinkAnhDaiDien)} />
									</a>
									<h2 className="post-title">
										<a href={`${data.news[0].LienKet}`}>{data.news[0].TieuDe}</a>
									</h2>
									<p className="post-date mb-2">
										{data.news[0].NgayXuatBan &&
											<Moment format="DD/MM/YYYY">
												{data.news[0].NgayXuatBan}
											</Moment>
										}
									</p>
									<p className="post-desc">
										{data.news[0].MoTa}
									</p>
								</>
							}
							<div className="divider-gray my-2" />
							<div className="list-style-ar mb-3">
								{data && data.news.map((item, index) => {
									if (index == 0) {
										return;
									}
									return (
										<a className="item" href={`${item.LienKet}`}>
											{item.TieuDe}
										</a>
									)
								})
								}
							</div>
						</div>
						<div className="col-md-6 col-12">
							<h3 className="main-subtitle">
								<a href="https://bovoinddn.molisa.gov.vn/phan-anh-kien-nghi">THỐNG KÊ Hỏi đáp trực tuyến</a>
							</h3>
							{/* <div className="inline">
                <div className="select-custom">
                  <select name id className="form-control">
                    <option value>Năm</option>
                    <option value>Tháng</option>
                    <option value>Tuần</option>
                  </select>
                </div>
              </div> */}
							<div className="box-chart-hdtt mt-3">
								<div className="circle-box">

									<HighchartsReact
										highcharts={Highcharts}
										containerProps={{ className: "chart-canvas" }}
										options={options}
									/>
									<div className="total">
										<span className="number">{data && data.faq && data.faq.total}</span>
										<span className="text-m">Câu hỏi</span>
									</div>
								</div>
								<div className="chart-note">
									<div className="item">
										<span className="shape" style={{ background: '#F2994A' }} />
										<span className="text-m tleft">Câu hỏi chưa trả lời</span>
										<span className="number">{data && data.faq && data.faq.chuatraloi}</span>
									</div>
									<div className="item">
										<span className="shape" style={{ background: '#28AE60' }} />
										<span className="text-m tleft">Câu hỏi đã trả lời</span>
										<span className="number">{data && data.faq && data.faq.datraloi}</span>
									</div>
									<div className="item">
										<span className="shape" style={{ background: '#EC5454' }} />
										<span className="text-m tleft">Câu hỏi không hợp lệ</span>
										<span className="number">{data && data.faq && data.faq.khonghople}</span>
									</div>
								</div>
							</div>
							<h3 className="main-subtitle">
								<a href="http://phanhoichinhsach.molisa.gov.vn/">Phản hồi chính sách</a>
							</h3>
							<div className="list-vb">
								{data && data.phcs.map((item) =>
									<div className="vb-item">
										<h4 className="post-title">
											<a href={`${item.LienKet}`} className="fw5">{item.TieuDe}</a>
										</h4>
										<div className="post-date">
											{item.NgayXuatBan &&
												<Moment format="DD/MM/YYYY">
													{item.NgayXuatBan}
												</Moment>
											}
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="box-sidebar">
					<BoxSuKien />
					{banners && banners.data.map((item, index) =>
						<a href={item.URL} className="banner mb-3">
							<img src={pathImg(item.AnhBannerUrl)} alt={item.AnhBannerUrl} />
						</a>
					)
					}
					{/* <a href="#" className="banner mb-3">
            <img src="assets/uploads/banners/9.png" alt="assets/uploads/banners/en/9.png" />
          </a>
          <a href="#" className="banner mb-3">
            <img src="assets/uploads/banners/7.png" alt="assets/uploads/banners/en/7.png" />
          </a> */}
				</div>
			</div>
		</>
	)
}
