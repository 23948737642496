
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, interleave, pathFile, pathImg, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import BoxSideBarVBCDDH from '../components/Common/BoxSideBarVBCDDH';
import { useAuth } from '../contexts/auth';
import Moment from 'react-moment';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { Helmet } from 'react-helmet';


export default function SuKienHoiThao() {
  const [searchParams, setSearchParams] = useSearchParams();
  const LoaiSuKienID = searchParams.get("LoaiSuKienID");

  // const [filter, setFilter] = useState([["LoaiHoiThao", "=",LoaiSuKienID]]);
  const [filter, setFilter] = useState([]);
  const [loaisukiens, setLoaisukiens] = useState();
  const [items, setItems] = useState();
  const [loading, setLoading] = useState(true);
  const [sukien, setSukien] = useState();

  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const params = useParams();
  const auth = useAuth();
  const id = params.id;

  useEffect(() => {
    (async function () {

      setLoading(true);
      const data = await request(`Public/LoaiSuKien?lang=vi`);
      setLoaisukiens(data);
      if (id) {
        const bans = await request(`/Public/SuKienHoiThaoDetail?ID=${id}`);
        setSukien(bans);
      }

      setLoading(false);
    })();
  }, [])
  useEffect(() => {
    if (!loading) {
      (async function () {
        reloadData(0);
      })();
    }
  }, [loading]);
  async function reloadData(newOffset) {
    let filterStr = JSON.stringify(interleave(filter, "and"));
    const vbs = await request(`Public/SuKienHoiThao?lang=vi&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true&filter=${filterStr}`);
    setTotalCount(vbs.totalCount);
    setItems(vbs.data);

    setLoading(false);
  }

  // Invoke when user click to request another page.
  const handlePageClick = async (pageNumber) => {
    const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
    setActivePage(pageNumber);
    reloadData(newOffset);
  };

  return (
    <>
      {!sukien &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Sự kiện hội thảo`}</title>
        </Helmet>
      }
      {sukien &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-${sukien.data.TenHoiThao}`}</title>
        </Helmet>
      }
      <div className="breadcrumb">
        <a href="/" className="breadcrumb-item">Trang chủ</a>
        <a href="/sukienhoithao" className="breadcrumb-item">Hội nghị - Sự kiện</a>
      </div>
      <h1 className="title-main main bold f18 mb-4 text-uppercase">HỘI NGHỊ - SỰ KIỆN</h1>
      <div className="row">
        <div className="col-12">
          {!sukien &&
            <>
              <div className="list-events">
                {items && items.map((item, index) =>
                  <div className="item">
                    <div className="post-title">
                      <a href={`/sukienhoithao/${item.ID}`}>{item.TenHoiThao}</a>
                    </div>
                    <div className="info">
                      <span className="icon isax-clock-11" />
                      <span className="text-m">Thời gian:{' '}
                        {item.ThoiGianTu &&
                          <Moment format="HH:mm, [ngày] DD/MM/YYYY">
                            {item.ThoiGianTu}
                          </Moment>
                        }
                        {' - '}
                        {item.ThoiGianDen &&
                          <Moment format="HH:mm, [ngày] DD/MM/YYYY">
                            {item.ThoiGianDen}
                          </Moment>
                        }
                      </span>
                    </div>
                    <div className="info">
                      <span className="icon isax-location1" />
                      <span className="text-m">Địa điểm: {item.DiaDiemToChuc}</span>
                    </div>
                    <div className="info">
                      <span className="icon isax-buildings1" />
                      <span className="text-m">Đơn vị tổ chức: {item.DonViToChucChinh}</span>
                    </div>
                  </div>
                )
                }
              </div>
              <div className="navi-page">
                <Pagination
                  activePage={activePage}
                  itemClass='page-item'
                  linkClass='page-link'
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageClick}
                />
              </div>
            </>
          }
          {sukien &&
            <>
              <div className="article article-detail">
                <h1 className="title">{sukien.data.TenHoiThao}</h1>
                <div className="event-info">
                  <div className="event-date">
                    <span className="month">
                      Tháng &nbsp;
                      {sukien.data.ThoiGianTu &&
                        <Moment format="MM">
                          {sukien.data.ThoiGianTu}
                        </Moment>
                      }
                    </span>
                    <span className="ct">
                      <span className="day">
                        {sukien.data.ThoiGianTu &&
                          <Moment format="DD">
                            {sukien.data.ThoiGianTu}
                          </Moment>
                        }
                      </span>
                      <span className="date">Thứ {moment(sukien.data.ThoiGianTu).day() + 1}</span>
                    </span>
                  </div>
                  <div className="text-content">
                    <div className="info">
                      <span className="icon isax-clock-11" />
                      <span className="text-m">Thời gian:
                        {sukien.data.ThoiGianTu &&
                          <Moment format="DD/MM/YYYY HH:mm">
                            {sukien.data.ThoiGianTu}
                          </Moment>
                        }
                        -
                        {sukien.data.ThoiGianDen &&
                          <Moment format="DD/MM/YYYY HH:mm">
                            {sukien.data.ThoiGianDen}
                          </Moment>
                        }
                      </span>
                    </div>
                    {sukien.data.DiaDiemToChuc &&
                      <div className="info">
                        <span className="icon isax-location1" />
                        <span className="text-m">Địa điểm: {sukien.data.DiaDiemToChuc}</span>
                      </div>
                    }
                    {sukien.data.DonViToChucChinh &&
                      <div className="info">
                        <span className="icon isax-buildings1" />
                        <span className="text-m">{sukien.data.DonViToChucChinh}</span>
                      </div>
                    }
                    {sukien.data.SuKienHoiThao_Files?.length > 0 &&
                      <div className="info">
                        <span className="icon isax-attach-circle1" />
                        <span className="text-m">
                          Tài liệu hội nghị:
                          {sukien.data.SuKienHoiThao_Files && sukien.data.SuKienHoiThao_Files.map((item) =>
                            <>
                              <a href={pathFile(item.File.LinkLuuTru)} className="btn btn-small main py-0">
                                {item.File.Ten}
                              </a>
                              <br />
                              <img src={`https://api.qrserver.com/v1/create-qr-code/?size=120x120&data=${pathFile(item.File.LinkLuuTru)}`} alt="ncsc.png" />
                            </>
                          )}
                        </span>
                      </div>
                    }
                  </div>
                  {sukien.data.AnhDaiDien &&
                    <div className="logo-box">
                        <div className="logo">
                            <img src={pathImg(sukien.data.AnhDaiDien)} alt={sukien.data.AnhDaiDien} />
                        </div>
                    </div>
                  }
                </div>
                <div className="event-note" dangerouslySetInnerHTML={{ __html: sukien.data.NoiDungThongTinMoi }}>
                </div>
              </div>
              <div className="divider-gray"></div>
              <h2 className="title-related">
                <span className="icon isax-calendar-1 main">

                </span>
                <span className="text-m f18">SỰ KIỆN KHÁC</span>
              </h2>
              <div className="list-events">
                {sukien.relates && sukien.relates.map((item, index) =>
                  <div className="item">
                    <div className="post-title">
                      <a href={`/sukienhoithao/${item.ID}`}>{item.TenHoiThao}</a>
                    </div>
                    <div className="info">
                      <span className="icon isax-clock-11" />
                      <span className="text-m">Thời gian:
                        {item.ThoiGianTu &&
                          <Moment format="DD/MM/YYYY HH:mm">
                            {item.ThoiGianTu}
                          </Moment>
                        }
                        -
                        {item.ThoiGianDen &&
                          <Moment format="DD/MM/YYYY HH:mm">
                            {item.ThoiGianDen}
                          </Moment>
                        }
                      </span>
                    </div>
                    {item.DiaDiemToChuc &&
                      <div className="info">
                        <span className="icon isax-location1" />
                        <span className="text-m">Địa điểm: {item.DiaDiemToChuc}</span>
                      </div>
                    }
                    {item.DonViToChucChinh &&
                      <div className="info">
                        <span className="icon isax-buildings1" />
                        <span className="text-m">{item.DonViToChucChinh}</span>
                      </div>
                    }
                  </div>
                )
                }
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
}
