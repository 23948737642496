
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import TreeView from 'devextreme-react/tree-view';
import config from '../config';
import { createCustomStore, listToTreeWithRoot, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TreeCoCauToChuc from '../components/Common/TreeCoCauToChuc';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import { isDesktop } from 'react-device-detect';


export default function CoCauToChuc() {
	const { t } = useTranslation();
	const [tochucs, setTochucs] = useState();
	const [tochuc, setTochuc] = useState();
	const params = useParams();
	const id = params.id;
	useEffect(() => {
		(async function () {
			const data = await request(`Public/CoCauToChuc?IDDVCha=2367`);
			let root = listToTreeWithRoot(data.data, 'ID', 'IDDVCha', 2367);
			setTochucs(root);
			if (id) {
				const bans = await request(`/Public/CoCauToChucDetail?ID=${id}`);
				setTochuc(bans);
			}
		})();
	}, [])
	useEffect(() => {
		if (isDesktop) {
			$(".co-cau-to-chuc .title").css({ 'margin-right': $('.co-cau-to-chuc .item.no-line').width() + $('.co-cau-to-chuc .item.no-line').width() * 3 / 4 });

		}
	})
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Cơ cấu tổ chức`}</title>
			</Helmet>
			<div class="container">
				<div class="breadcrumb">
					<a href="/" className="breadcrumb-item">Trang chủ</a>
					<a href="/cocautochuc" class="breadcrumb-item">Cơ cấu tổ chức</a>
				</div>
				<div class="co-cau-to-chuc">
					<div class="title">SƠ ĐỒ CƠ CẤU TỔ CHỨC BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI</div>
					<div class="list-child-cctc">
						{tochucs && tochucs.map((item, index) => {
							return (
								<>
									<TreeCoCauToChuc tochucs={item.children} title={item.TenDonVi} className={(index == tochucs.length - 1) ? "no-line" : ""} />
								</>
							)
						}
						)}
					</div>
				</div>
			</div>
			{tochuc &&
				<>
					<div dangerouslySetInnerHTML={{ __html: tochuc.data.NoiDung }} />
				</>
			}
		</>
	);
}
