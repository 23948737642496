
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, pathImg, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';
import Pagination from "react-js-pagination";


export default function TrangThanhPhan({ }) {
    const params = useParams();

    const [itemsPerPage, setItemsPerPage] = useState(18);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [nhansu, setNhansu] = useState();
    const [items, setItems] = useState();

    useEffect(() => {
        (async function () {
            // const vbs = await request(`Public/Partners?skip=0&take=${itemsPerPage}&requireTotalCount=true`);
            const vbs = await request(`Public/Partners?skip=0&requireTotalCount=true`);
            setTotalCount(vbs.totalCount);
            setItems(vbs.data);
        })();
    }, [])


    // Invoke when user click to request another page.
    // const handlePageClick = async (pageNumber) => {
    //     const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
    //     // const vbs = await request(`Public/Partners?skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
    //     const vbs = await request(`Public/Partners?skip=${newOffset}&requireTotalCount=true`);
    //     setActivePage(pageNumber);
    //     setTotalCount(vbs.totalCount);
    //     setItems(vbs.data);
    // };

    const handleChangePage = (event) => {
        setItemsPerPage(event.target.value);
    };
    const groupByPosition = (items = []) => {
        return items.reduce((acc, item) => {
            const { TenViTri } = item;
            if (!acc[TenViTri]) {
                acc[TenViTri] = [];
            }
            acc[TenViTri].push(item);
            return acc;
        }, {});
    };
    const groupedItems = groupByPosition(items);
    return (
        <>
            <div className="breadcrumb">
                <a href="/" className="breadcrumb-item">Trang chủ</a>
                <a href="/trangthanhphan" className="breadcrumb-item">Trang thành phần</a>
            </div>

            <h5><b>CÁC ĐƠN VỊ THUỘC BỘ</b></h5>
            <div className="list-partners tleft mb-4">
                {Object.keys(groupedItems).map((position) => (
                    <div key={position} className="position-group">
                        {/* <h4>{position}</h4> */}
                        <div className="list-item">
                            {groupedItems[position].map((item) => (
                                item.ID_ViTri === 3230 && (
                                    <div className="item" key={item.ID}>
                                        <a href={item.URL || '#'}>
                                            <img src={pathImg(item.AnhBannerUrl)} alt={item.TenBanner} />
                                            <span className="text-m">{item.TenBanner}</span>
                                        </a>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <h5><b>CÁC ĐƠN VỊ TRỰC THUỘC BỘ</b></h5>
            <div className="list-partners tleft">
                {Object.keys(groupedItems).map((position) => (
                    position !== "Các đơn vị thuộc bộ" && (
                        <div key={position} className="position-group">
                            <h5>{position}</h5>
                            <div className="list-item">
                                {groupedItems[position].map((item) => (
                                    item.ID_ViTri !== 3230 && (
                                        <div className="item" key={item.ID}>
                                            <a href={item.URL || '#'}>
                                                <img src={pathImg(item.AnhBannerUrl)} alt={item.TenBanner} />
                                                <span className="text-m">{item.TenBanner}</span>
                                            </a>
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                    )))}
            </div>

            {/* <div className="navi-page">
                <Pagination
                    activePage={activePage}
                    itemClass="page-item"
                    linkClass="page-link"
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageClick}
                />
            </div> */}
        </>
    );
}
