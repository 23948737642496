import React, { useEffect, useState } from 'react';
import { request } from '../../utils/api';
import Moment from 'react-moment';
import Slider from 'react-slick';
export default function BoxSuKien({ title="HỘI NGHỊ - SỰ KIỆN", children }) {
  const [sukiens, setSukiens] = useState();
  useEffect(() => {
      (async function () {
          const data = await request(`Public/BoxSuKien`);
          setSukiens(data);
      })();
  }, [])
  var settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      autoplaySpeed: 6000,
      autoplay: true,
      arrows: false,
  };
  return (
    <>
    {sukiens && sukiens.data.length > 0 && 
      <div className="box-sidebar-hnsk">
        <div className="title"><a href="/sukienhoithao">{title}</a></div>
        <div className="body" style={{backgroundImage: "url(/assets/images/bg-ht.png)"}}>
          <Slider className="list-hnsk" {...settings}>
            {sukiens && sukiens.data.map((item) =>
              <div className="item">
                <p className="font-weight-bold ht-title"><a href={`/sukienhoithao/${item.ID}`}>{item.TenHoiThao}</a></p>
                <div className="info">
                  <span className="icon isax-location1" />
                  <span className="text-m"><span className="fw6">Địa điểm:</span> {item.DiaDiemToChuc}</span>
                </div>
                <div className="info">
                  <span className="icon isax-clock-11" />
                  <span className="text-m"><span className="fw6">Thời gian:{' '}</span> 
                    {item.ThoiGianTu && 
                      <Moment format="HH:mm ngà\y DD/MM/YYYY">
                          {item.ThoiGianTu}
                      </Moment>
                    }
                    {/* -
                    {item.ThoiGianDen && 
                      <Moment format="DD/MM/YYYY HH:mm">
                          {item.ThoiGianDen}
                      </Moment>
                    } */}
                  </span>
                </div>
                <div className="info">
                  <span className="icon isax-people1" />
                  <span className="text-m"><span className="fw6">Đơn vị tổ chức:</span> {item.DonViToChucChinh}</span>
                </div>
              </div>
            )}
          </Slider>
        </div>
      </div>
    }
    </>
  )
}
