import React, { useEffect, useState } from 'react';
import { pathFile, pathImg, request } from '../../utils/api';
import Moment from 'react-moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';

import config from '../../config';
export default function BoxVBPL({ title, limit = 4, children }) {
  const [vanbans, setVanbans] = useState();
  const [banners, setBanners] = useState();
  useEffect(() => {
    (async function () {
      const vbs = await request(`Public/BoxVBPL?limit=${limit}`);
      setVanbans(vbs);
      const data = await request(`/Public/BannerByViTri?ID_ViTri=${window.env.REACT_APP_BOXVBPL_BANNER_TRANG_CHU_TIENG_VIET}`);
      setBanners(data);
    })();
  }, [])
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      let keyword = event.target.value;
      window.open(`/vanbanqppl/${keyword}`, '_self');
    }
  }
  return (
    <>
      <div className="main-title">
        <div className="title">
          <span className="icon isax-document-text" />
          <h2 className="text-m"><a href={`/vanbanchidao`}>HỆ THỐNG VĂN BẢN</a></h2>
        </div>
        <div className="input-search">
          <span className="icon isax-search-normal-11" />
          <input type="text" className="form-control" placeholder="Tìm kiếm Văn bản QPPL" title="Tìm kiếm Văn bản QPPL" onKeyPress={handleKeyPress} />
        </div>
      </div>
      <div className="htvb-box mb-4">
        <div className="box-content">
          <div className="row">
            <div className="col-md-6 col-12">
              <h3 className="main-subtitle">
                <a href={`/vanbanchidao`}>VĂN BẢN CHỈ ĐẠO - ĐIỀU HÀNH</a>
              </h3>
              <div className="list-vb mb-3">
                <PerfectScrollbar className="p-scrollbar relative" style={{ position: 'relative', height: '350px' }}>
                  {vanbans && vanbans.cddhs.map((item) =>
                    <div className="vb-item">
                      <div className="top">
                        <h4 className="post-title mb-0 text-uppercase">
                          <a href={`/vanbanchidao/${item.ID}`}>{item.SoKyHieu}{' '}
                            <em className="post-date normal" style={{ textTransform: "none" }}>
                              {item.NgayBanHanh &&
                                <>
                                  (Ngày: {' '}
                                  <Moment format="DD/MM/YYYY">
                                    {item.NgayBanHanh}
                                  </Moment>
                                  )
                                </>
                              }
                            </em>
                          </a>
                        </h4>
                        {item.VanBan_Files && item.VanBan_Files.map((item) =>
                          <a href={pathFile(item.File.LinkLuuTru)} className="btn btn-small main py-0" target={'_blank'}>
                            <i className="icon isax-document-download1" />
                            Tải xuống
                          </a>
                        )}
                      </div>
                      <div className="post-desc">
                        <a href={`/vanbanchidao/${item.ID}`}>{item.TrichYeu}</a>
                      </div>
                    </div>
                  )}
                </PerfectScrollbar>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <h3 className="main-subtitle">
                <a href="http://duthaovanban.molisa.gov.vn/">Dự thảo văn bản QPPL</a>
              </h3>
              <div className="list-vb">
                <PerfectScrollbar className="p-scrollbar relative" style={{ position: 'relative', height: '350px' }}>

                  {vanbans && vanbans.duthaos.map((item) =>
                    <div className="vb-item">
                      <div className="top">
                        <div className="post-date">
                          {item.NgayBatDau &&
                            <>
                              Ngày bắt đầu:&nbsp;
                              <Moment format="DD/MM/YYYY">
                                {item.NgayBatDau}
                              </Moment>
                            </>
                          }
                        </div>
                        <div className="post-date">
                          {item.NgayHetHan &&
                            <>
                              Ngày hết hạn:&nbsp;
                              <span className={moment().isSameOrAfter(item.NgayHetHan) ? '' : "text-danger"}>
                                <Moment format="DD/MM/YYYY">
                                  {item.NgayHetHan}
                                </Moment>
                              </span>
                            </>
                          }
                        </div>
                      </div>
                      <div className="post-desc">
                        <a href={`${item.LienKet}`}>{item.TieuDe}</a>
                      </div>
                      <div className="bottom">
                        <a href={`${item.LienKet}`} className="btn btn-small main">
                          <i className="icon isax-edit1" />
                          Góp ý
                        </a>
                      </div>
                    </div>
                  )}

                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
        <div className="box-sidebar">
          {banners && banners.data.map((item, index) =>
            <a href={item.URL} className="banner mb-3">
              <img src={pathImg(item.AnhBannerUrl)} alt={item.AnhBannerUrl} />
            </a>
          )
          }
          <div className="box-categories">
            <a href={`${window.env.REACT_APP_CONG_KHAI_NGAN_KHACH_URL}`}>
              <span className="icon isax-folder-open" />
              <span className="text-m">Công khai ngân sách</span>
            </a>
            <a href="/duan">
              <span className="icon isax-book" />
              <span className="text-m">Chương trình - Đề án - Dự án</span>
            </a>
            
            <a href={`${window.env.REACT_APP_DANH_MUC_THONG_TIN}`}>
              <span className="icon isax-category-2" />
              <span className="text-m">Danh mục thông tin</span>
            </a>
			<a href="http://khcn.molisa.gov.vn/">
              <span className="icon isax-teacher" />
              <span className="text-m">Khoa học - Công nghệ</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
